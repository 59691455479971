
import Navbar from '@/components/Navbar';
import Hero from '@/components/Hero';
import About from '@/components/About';
import Eligibility from '@/components/Eligibility';
import Recipients from '@/components/Recipients';
import Footer from '@/components/Footer';
import { Helmet } from 'react-helmet-async';

const Index = () => {
  // Structured data for search engines (Schema.org)
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "AQ Paraidrottsstipendium",
    "alternateName": "Axelsson Qviberg Paraidrottsstipendium",
    "url": "https://aqstipendium.se",
    "logo": "https://aqstipendium.se/logo.png",
    "description": "Stipendium för paraidrott grundat av Axelsson och Qviberg som ger ekonomiskt stöd till idrottare inom parasport.",
    "keywords": ["stipendium", "paraidrott", "axelsson", "qviberg", "sport", "stöd till paraidrott"],
    "founder": [
      {
        "@type": "Person",
        "name": "Erik Axelsson",
        "jobTitle": "Grundare"
      },
      {
        "@type": "Person",
        "name": "Johan Qviberg",
        "jobTitle": "Grundare"
      }
    ],
    "funder": {
      "@type": "Organization",
      "name": "Quinary Investment"
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>AQ Paraidrottsstipendium</title>
        <meta name="description" content="Axelsson Qviberg Paraidrottsstipendium stödjer idrottare som efter skada eller sjukdom vill fortsätta inom paraidrott. Grundat av Erik Axelsson och Johan Qviberg." />
        <script 
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
        />
      </Helmet>
      <Navbar />
      <Hero />
      <About />
      <Eligibility />
      <Recipients />
      <Footer />
    </div>
  );
};

export default Index;
