
import React from 'react';
import { AspectRatio } from '@/components/ui/aspect-ratio';

const About = () => {
  return (
    <section id="about" className="py-16 md:py-24 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold text-center text-scholarship-dark mb-8">
            Om AQ Paraidrottsstipendium
          </h2>
          
          <div className="space-y-6 text-gray-700">
            <p>
              Stipendiet har till ändamål att med finansiella medel stödja idrottare som drabbats av en allvarlig skada eller sjukdom som omöjliggör fortsatt idrottsutövande på samma nivå. Stipendiet är tillägnat åt personer som har som ambition att ta sig tillbaka till en aktiv livsstil och paraidrott.
            </p>
            
            <div className="mb-8">
              <div className="bg-white rounded-lg shadow-md overflow-hidden">
                <img 
                  src="/lovable-uploads/1395e233-1df8-4c92-a20b-75609f6d9459.png" 
                  alt="Stipendieutdelning" 
                  className="w-full rounded-lg object-cover"
                  style={{ 
                    width: '100%',
                    height: 'auto',
                    maxWidth: '100%',
                    imageRendering: 'auto'
                  }}
                />
              </div>
              <p className="text-sm text-gray-500 mt-2 text-center">Stipendieutdelning i Stockholm 2022</p>
            </div>
            
            <p>
              AQ Paraidrottsstipendium är ett samarbete med (den tidigare) längdskidåkaren Erik Axelsson och Johan Qviberg. Stipendiet bildades 2019 efter Erik förlamades från bröstet och ned efter en cykelolycka.
            </p>
            
            <div className="mt-10 p-6 bg-scholarship-light rounded-lg border-l-4 border-scholarship-blue">
              <h3 className="text-xl font-semibold text-scholarship-dark mb-3">Stipendiets omfattning</h3>
              <ul className="space-y-2 ml-1">
                <li className="flex items-start">
                  <span className="inline-block w-1 h-1 rounded-full bg-scholarship-dark mt-2.5 mr-3"></span>
                  <span>Stipendiesumman uppgår till 85 000 kr</span>
                </li>
                <li className="flex items-start">
                  <span className="inline-block w-1 h-1 rounded-full bg-scholarship-dark mt-2.5 mr-3"></span>
                  <span>Hela stipendiesumman tilldelas till en vinnare varje år</span>
                </li>
                <li className="flex items-start">
                  <span className="inline-block w-1 h-1 rounded-full bg-scholarship-dark mt-2.5 mr-3"></span>
                  <span>Stipendieutdelning sker i maj/juni i Stockholm</span>
                </li>
                <li className="flex items-start">
                  <span className="inline-block w-1 h-1 rounded-full bg-scholarship-dark mt-2.5 mr-3"></span>
                  <span>Mottagaren av stipendiet förväntas delta vid stipendieutdelningen</span>
                </li>
                <li className="flex items-start">
                  <span className="inline-block w-1 h-1 rounded-full bg-scholarship-dark mt-2.5 mr-3"></span>
                  <span>Tidigare stipendiemottagare förväntas vara delaktig vid följande års utdelning</span>
                </li>
              </ul>
            </div>
            
            <div className="mt-10 p-6 bg-scholarship-light rounded-lg border-l-4 border-scholarship-blue">
              <h3 className="text-xl font-semibold text-scholarship-dark mb-3">Jury</h3>
              <p className="mb-3">Mottagaren av stipendiet utses av juryn bestående av:</p>
              <ul className="space-y-2 ml-1">
                <li className="flex items-start">
                  <span className="inline-block w-1 h-1 rounded-full bg-scholarship-dark mt-2.5 mr-3"></span>
                  <span>Erik Axelsson - f.d. längdskidåkare, VD Quinary Investment</span>
                </li>
                <li className="flex items-start">
                  <span className="inline-block w-1 h-1 rounded-full bg-scholarship-dark mt-2.5 mr-3"></span>
                  <span>Johan Qviberg - nordisk mästare i optimistjolle (1996), ordförande Quinary Investment</span>
                </li>
                <li className="flex items-start">
                  <span className="inline-block w-1 h-1 rounded-full bg-scholarship-dark mt-2.5 mr-3"></span>
                  <span>Mathias Fredriksson - f.d. längdskidåkare, vinnare av den totala världscupen säsongen 2002/2003</span>
                </li>
                <li className="flex items-start">
                  <span className="inline-block w-1 h-1 rounded-full bg-scholarship-dark mt-2.5 mr-3"></span>
                  <span>Stina Nilsson - aktiv längdskidåkare, olympisk mästare (2018), världsmästare (2019)</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
