
const Footer = () => {
  return (
    <footer className="bg-scholarship-dark text-white py-10">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h3 className="font-bold text-lg mb-4">AQ Paraidrottsstipendium</h3>
            <p className="text-gray-300">
              Fortsatt idrottande med nya förutsättningar
            </p>
          </div>
          <div>
            <h3 className="font-bold text-lg mb-4">Snabblänkar</h3>
            <ul className="space-y-2">
              <li><a href="#about" className="text-gray-300 hover:text-white transition-colors">Om stipendiet</a></li>
              <li><a href="#eligibility" className="text-gray-300 hover:text-white transition-colors">Ansök</a></li>
              <li><a href="#recipients" className="text-gray-300 hover:text-white transition-colors">Tidigare stipendiater</a></li>
            </ul>
          </div>
        </div>
        <div className="border-t border-gray-700 mt-8 pt-8 flex flex-col md:flex-row justify-between items-center">
          <p className="text-gray-400 text-sm">© {new Date().getFullYear()} AQ Paraidrottsstipendium. Alla rättigheter förbehållna.</p>
          <div className="mt-4 md:mt-0">
            <a href="#" className="text-gray-400 hover:text-white text-sm mr-4">Integritetspolicy</a>
            <a href="#" className="text-gray-400 hover:text-white text-sm">Villkor</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
