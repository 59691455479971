
import { useState } from 'react';
import { Menu, X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navLinks = [
    { name: "Hem", href: "#" },
    { name: "Om stipendiet", href: "#about" },
    { name: "Kriterier", href: "#eligibility" },
    { name: "Tidigare stipendiater", href: "#recipients" }
  ];

  return (
    <nav className="bg-white shadow-sm sticky top-0 z-50">
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        <a href="#" className="font-bold text-xl text-scholarship-blue">AQ Paraidrottsstipendium</a>
        
        {/* Mobile menu button */}
        <div className="md:hidden">
          <Button variant="ghost" size="icon" onClick={toggleMenu} aria-label={isOpen ? "Stäng meny" : "Öppna meny"}>
            {isOpen ? <X /> : <Menu />}
          </Button>
        </div>
        
        {/* Desktop menu */}
        <div className="hidden md:flex space-x-6">
          {navLinks.map((link) => (
            <a key={link.name} href={link.href} className="text-gray-700 hover:text-scholarship-blue transition-colors">
              {link.name}
            </a>
          ))}
        </div>

        {/* Mobile menu drawer */}
        <div className={cn(
          "fixed top-16 right-0 w-full bg-white shadow-lg transition-transform duration-300 ease-in-out transform md:hidden",
          isOpen ? "translate-x-0" : "translate-x-full"
        )}>
          <div className="flex flex-col p-4 space-y-4">
            {navLinks.map((link) => (
              <a 
                key={link.name} 
                href={link.href} 
                className="text-gray-700 hover:text-scholarship-blue transition-colors py-2 px-4"
                onClick={() => setIsOpen(false)}
              >
                {link.name}
              </a>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
