
import { Check } from 'lucide-react';

const Eligibility = () => {
  return (
    <section id="eligibility" className="py-16 md:py-24 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold text-center text-scholarship-dark mb-8">
            Ansökningskriterier
          </h2>
          
          <div className="bg-white rounded-lg shadow-lg p-6 md:p-8 mb-10">
            <div className="space-y-8">
              <div className="flex items-start">
                <div className="flex-shrink-0 w-6 mr-3">
                  <Check className="h-6 w-6 text-scholarship-blue" />
                </div>
                <p>Har bedrivit idrott på elitnivå</p>
              </div>
              <div className="flex items-start">
                <div className="flex-shrink-0 w-6 mr-3">
                  <Check className="h-6 w-6 text-scholarship-blue" />
                </div>
                <p>Har ådragit sig en bestående funktionsnedsättning från sjukdom eller skada som omöjliggör fortsatt idrottsutövande på samma nivå</p>
              </div>
              <div className="flex items-start">
                <div className="flex-shrink-0 w-6 mr-3">
                  <Check className="h-6 w-6 text-scholarship-blue" />
                </div>
                <p>Har som ambition att engagera sig inom paraidrott</p>
              </div>
              <div className="flex items-start">
                <div className="flex-shrink-0 w-6 mr-3">
                  <Check className="h-6 w-6 text-scholarship-blue" />
                </div>
                <p>Mottagaren skall ha skadat sig under de senaste 5 åren</p>
              </div>
              <div className="flex items-start">
                <div className="flex-shrink-0 w-6 mr-3">
                  <Check className="h-6 w-6 text-scholarship-blue" />
                </div>
                <p>Mottagaren ska vara inom åldersspannet 15-35 år</p>
              </div>
              <div className="flex items-start">
                <div className="flex-shrink-0 w-6 mr-3">
                  <Check className="h-6 w-6 text-scholarship-blue" />
                </div>
                <p>Mottagaren ska vara en svensk medborgare</p>
              </div>
            </div>
          </div>
          
          <div className="bg-scholarship-light p-6 md:p-8 rounded-lg border-l-4 border-scholarship-yellow">
            <h3 className="text-xl font-semibold text-scholarship-dark mb-4">Ansökningsprocess</h3>
            <p className="mb-6 text-gray-700">
              Sökanden skall bifoga ett personligt brev som beskriver sin skada och historia. I brevet skall det även framkomma vad stipendiemedlen skall komma att användas till. Ansökan skickas som PDF-fil till erikandersaxelsson@gmail.com. I ansökan skall följande information inkluderas:
            </p>
            <ol className="list-decimal ml-5 space-y-4 text-gray-700 mb-6">
              <li className="pl-1"><span>Sökandes namn, personnummer, bostadsadress, postort, telefonnummer</span></li>
              <li className="pl-1"><span>Beskrivning av sin idrottskarriär innan skadan</span></li>
              <li className="pl-1"><span>Beskrivning av skadan / sjukdomen och var när det hände</span></li>
              <li className="pl-1"><span>Beskrivning av den permanenta funktionsnedsättningen</span></li>
              <li className="pl-1"><span>Vilken eller vilka paraidrotter som du väntas börja utöva eller redan utövar</span></li>
              <li className="pl-1"><span>Övrig information som du vill delge</span></li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Eligibility;
