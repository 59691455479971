
import { Card, CardContent } from '@/components/ui/card';
import { 
  Carousel, 
  CarouselContent, 
  CarouselItem, 
  CarouselNext, 
  CarouselPrevious 
} from '@/components/ui/carousel';
import { useEffect, useState } from 'react';

interface RecipientProps {
  name: string;
  year: string;
  sport: string;
  achievement: string;
  quote: string;
}

const RecipientCard = ({ name, year, sport, achievement, quote }: RecipientProps) => {
  return (
    <Card className="h-full">
      <CardContent className="p-6">
        <p className="font-bold text-scholarship-blue text-xl mb-2">{name}</p>
        <div className="flex justify-between items-center mb-4">
          <p className="text-base text-gray-500">Stipendiat {year}</p>
          <p className="text-base font-medium">{sport}</p>
        </div>
        {achievement && <p className="text-gray-700 mb-4">{achievement}</p>}
        <blockquote className="italic text-gray-600 border-l-2 border-scholarship-yellow pl-4 text-base">
          "{quote}"
        </blockquote>
      </CardContent>
    </Card>
  );
};

const Recipients = () => {
  const [api, setApi] = useState<any>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  
  // Auto-scroll functionality
  useEffect(() => {
    if (!api) return;
    
    const interval = setInterval(() => {
      api.scrollNext();
    }, 5000); // Scroll every 5 seconds
    
    return () => clearInterval(interval);
  }, [api]);

  // Update current slide indicator when slide changes
  useEffect(() => {
    if (!api) return;
    
    const onSelect = () => {
      setCurrentSlide(api.selectedScrollSnap());
    };
    
    api.on("select", onSelect);
    return () => {
      api.off("select", onSelect);
    };
  }, [api]);

  const recipients = [
    {
      name: "Hampus Linder-Olofsson",
      year: "2023/2024",
      sport: "Rullstolstennis",
      achievement: "",
      quote: "Hampus Linder-Olofsson har på kort tid seglat upp som Sveriges mest lovande rullstolstennisspelare. Bara ett par år in i karriären är hampus redan meriterad med flera SM-guld och har etablerat sig som en topp-100 spelare på den internationella scenen. Med ett enormt driv och en positiv inställning till allt han tar sig an är han en stor inspirationskälla till alla i sin omgivning och en mycket värdig mottagare av AQ Paraidrottsstipendium 2023. Vi önskar Hampus ett stort lycka till i sin resa mot Paralympics i Paris 2024"
    },
    {
      name: "Felicia Grimmenhag",
      year: "2023/2024",
      sport: "Paradressyr",
      achievement: "",
      quote: "Felicia Grimmenhag är idag en av Sveriges främsta pararyttare med siktet inställt mot Paralympics i Paris 2024. Felicias mentala styrka, positiva personlighet och enorma driv inspirerar alla i hennes närhet. När Felicia inte sitter på hästryggen föreläser hon om sitt unika perspektiv och alla lärdomar hon har dragit från sin skada. Felicia är en mycket värdig mottagare av AQ Paraidrottsstipendium 2023. Vi önskar Felicia ett stort lycka till i sin resa mot Paralympics i Paris 2024."
    },
    {
      name: "Ebba Einarsson",
      year: "2021/2022",
      sport: "Pararodd",
      achievement: "",
      quote: "Ebba Einarsson var länge en av Sveriges mest lovande roddtalanger, med guldmedaljer från både junior- och senior-SM på meritlistan. Sommaren 2017 drabbades Ebba av en blödning i ryggmärgen som resulterade i en permanent ryggmärgsskada och en förlamning från midjan och ned. Bara ett år efter skadan var Ebba tillbaka i tävlingssammanhang, denna gång i en anpassad båt, tävlandes i pararodd. Kärleken till idrotten och rodden består och idag är siktet inställt på Paris och Paralympics som går av stapeln under sommaren 2024. Ebbas järnvilja och enastående driv inspirerar alla i hennes omgivning och hon är en mycket värdig mottagare av AQ Paraidrottsstipendium"
    },
    {
      name: "Tobias Forsberg",
      year: "2021/2022",
      sport: "Ishockey",
      achievement: "",
      quote: "Tobias Forsberg har under sin långa karriär som professionell hockeyspelare hunnit med allt från TV-pucken, juniorlandslaget, Allsvenskan och SHL. Under en hockeymatch i december 2018 var olyckan framme efter en kollision med sargen som resulterade i flera brutna nackkotor och en permanent skada på ryggmärgen. Tobias var tvungen att avsluta sin professionella hockeykarriär med det tog inte länge för honom att hitta tillbaka till hockeyn – idag jobbar Tobias som expertkommentator på C More och har som ambition att börja föreläsa om alla lärdomar han dragit från idrotten och sin skada. Tobias är en stor inspirationskälla och en glädjespridare och en mycket värdig mottagare av AQ Paraidrottsstipendium"
    },
    {
      name: "Anna Holmlund",
      year: "2020",
      sport: "Skicross",
      achievement: "",
      quote: "Anna Holmlund har av en enig jury utsetts till 2020 års mottagare av Axelsson Qviberg Paraidrottsstipendium. Under sin aktiva karriär hann Anna med att vinna såväl VM-guld som OS-brons i skicross. I december 2016 var olyckan framme, något som tvingade Anna att avsluta sin aktiva karriär. Trots detta har hon uppvisat samma järnvilja som tog henne till världstoppen i skicross. Anna är en inspirationskälla och en synnerligen värdig vinnare av Stipendiet!"
    }
  ];

  return (
    <section id="recipients" className="py-16 md:py-24 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-scholarship-dark mb-8">
          Tidigare stipendiater
        </h2>
        <p className="text-center text-gray-700 mb-12 max-w-2xl mx-auto">
          Lär känna några av våra tidigare stipendiater och motiveringen bakom.
        </p>
        
        <Carousel
          setApi={setApi}
          className="w-full max-w-5xl mx-auto"
          opts={{
            align: "start",
            loop: true,
          }}
        >
          <CarouselContent className="px-4">
            {recipients.map((recipient, index) => (
              <CarouselItem key={index} className="md:basis-1/2 pl-4 pr-4">
                <RecipientCard
                  name={recipient.name}
                  year={recipient.year}
                  sport={recipient.sport}
                  achievement={recipient.achievement}
                  quote={recipient.quote}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <div className="flex items-center justify-center mt-8 space-x-2">
            <CarouselPrevious className="relative static left-0 right-auto translate-y-0 mr-2" />
            <div className="flex space-x-1">
              {recipients.map((_, index) => (
                <button
                  key={index}
                  className={`w-2 h-2 rounded-full ${
                    currentSlide === index ? "bg-scholarship-blue" : "bg-gray-300"
                  }`}
                  onClick={() => api?.scrollTo(index)}
                />
              ))}
            </div>
            <CarouselNext className="relative static right-0 left-auto translate-y-0 ml-2" />
          </div>
        </Carousel>
      </div>
    </section>
  );
};

export default Recipients;
