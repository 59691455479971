
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div className="relative bg-scholarship-light py-24 md:py-32">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h1 className="text-3xl md:text-4xl font-bold text-scholarship-dark mb-2">
            Axelsson Qviberg
          </h1>
          <h2 className="text-3xl md:text-4xl font-bold text-scholarship-dark mb-6">
            Paraidrottsstipendium
          </h2>
          <p className="text-xl md:text-2xl text-gray-700 mb-8 italic">
            Fortsatt idrottande med nya förutsättningar
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <Button 
              className="bg-scholarship-blue hover:bg-scholarship-dark text-white"
              onClick={() => document.getElementById('about')?.scrollIntoView({ behavior: 'smooth' })}
            >
              Läs mer
            </Button>
            <Button 
              variant="outline" 
              className="border-scholarship-blue text-scholarship-blue hover:bg-scholarship-light"
              onClick={() => document.getElementById('eligibility')?.scrollIntoView({ behavior: 'smooth' })}
            >
              Ansök nu
            </Button>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent"></div>
    </div>
  );
};

export default Hero;
